import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
import appStrings from '@/app/utility/string.utility.js';
import store from '../../../../../../../store';
import commonHelper from '../../../../../../utility/common.helper.utility';
import commonhelper from '@/app/utility/mixin';
import taxCategory from '../../../../../taxation/taxCategory';
import { required } from 'vuelidate/lib/validators';
import codeCombination from '@/app/components/shared/codeCombination';
export default {
  name: 'addEditTenantRecovery',
  components: {
    ModelSelect,
    commonhelper,
    DatePicker,
    taxCategory,
    codeCombination
  },
  mixins: [commonhelper],
  data() {
    return {
      showAlert: false,
      isSuccess: false,
      responseMsg: [],
      isBusy: true,
      loader: false,
      showValueSetModal: false,
      editMode: false,
      showTaxCategory: false,
      showConcatSegmentModal: false,
      parent_value_set_id: null,
      areaType: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      fmsProject: {
        value: null,
        text: null,
        code: null
      },
      trxType: {
        value: null,
        text: null
      },
      trxTypeList: [],
      locationList: [],
      recoveryMode: {
        value: null,
        text: null
      },
      recoveryModeList: [
        {
          value: 'fms_unit',
          text: 'FMS Unit'
        },
        {
          value: 'fms_sub_unit',
          text: 'FMS Sub Unit'
        }
      ],
      scheduleType: {
        value: 'SRECOVERY',
        text: 'Recovery'
      },
      recoveryType: {
        value: null,
        text: null
      },
      batchName: null,
      batchId: 0,
      prjType: 'LEASE',
      batchDate: format(new Date(), appStrings.DATEFNSFORMAT),
      description: null,
      lineDescription: null,
      area: null,
      ratePerSqft: null,
      rateUom: {
        value: null,
        text: null
      },
      recBasis: {
        value: null,
        text: null
      },
      expensesAcc: null,
      expensesAccCcid: null,
      expensesAccMeaning: null,
      liabilityAcc: null,
      liabilityAccCcid: null,
      liabilityAccMeaning: null,
      fromDate: null,
      toDate: null,
      taxCategory: {
        value: null,
        text: null
      },
      location: {
        location_id: null,
        location_name: null
      },
      sacCode: null,
      hsnCode: null,
      status: 'NEW',
      createdBy: this.getUserName(),
      creationDate: format(new Date(), appStrings.DATEFNSFORMAT),
      lastUpdateBy: null,
      lastUpdateDate: null,
      depVsetParam: null,
      showCodeCombinationModal: false,
      accountType: null,
      combinationDetails: {
        le_id: null,
        project_code: null
      }
    };
  },
  validations: {
    legalEntity: {
      text: { required }
    },
    operatingUnit: {
      text: { required }
    },
    fmsProject: {
      text: { required }
    },
    location: {
      location_name: { required }
    },
    trxType: {
      text: {
        required
      }
    },
    recoveryType: {
      text: { required }
    },
    batchDate: { required },
    recBasis: {
      text: { required }
    },
    rateUom: {
      text: { required }
    },
    fromDate: { required },
    toDate: { required },
    expensesAcc: { required },
    liabilityAcc: { required }
  },
  mounted() {
    if (this.batchId === 0) {
      this.editMode = true;
    }
    this.getTxnTypeList();
    this.getUomClass();

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditLeaseTenantRecovery();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditLeaseTenantRecovery() {
      // if (this.sacCode || this.hsnCode) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const payload = {
            lease_tenant_recovery_batch_details: {
              lease_tenant_recovery_batch_id: 0,
              le_id: Number(this.legalEntity.value),
              ou_id: Number(this.operatingUnit.value),
              lease_prj_id: Number(this.fmsProject.value),
              location_id: Number(this.location.location_id),
              trx_type_dtl_id: Number(this.trxType.value),
              schedule_type_vset: this.scheduleType.value,
              recovery_type_vset: this.recoveryType.value,
              batch_date: this.batchDate,
              batch_description: this.description,
              recovery_basis: this.recBasis.value,
              recovery_rate_uom_vset: this.rateUom.value,
              recovery_rate: Number(this.ratePerSqft),
              period_from: this.fromDate,
              period_to: this.toDate,
              charge_ac_ccid: Number(this.expensesAccCcid),
              debtor_ac_ccid: Number(this.liabilityAccCcid),
              tax_cat_id: Number(this.taxCategory.value),
              sac_code: Number(this.sacCode),
              hsn_code: Number(this.hsnCode),
              batch_status_vset: this.status,
              line_description: this.lineDescription
            }
          };
          this.loader = true;
          this.$store
            .dispatch('leaseRecovery/addEditLeaseTenantRecovery', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                this.batchId =
                  response.data.data.lease_tenant_recovery_batch_id;
                this.batchName = response.data.data.batch_name;
                this.status = response.data.data.batch_status_vset;
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      // } 
      // else {
      //   alert('Please Enter SAC or HSN Code');
      // }
    },
    getUserName() {
      const tempName = store.state.auth.userName;
      const name = tempName.slice(tempName.lastIndexOf('-') + 1);
      return name;
    },
    setToday(date) {
      if (this.startDate(date)) {
        return date;
      }
    },
    getProjectBasedLocation() {
      const payload = {
        prjType: this.prjType,
        prjId: this.fmsProject.value
      };
      this.$store
        .dispatch('leaseRecovery/getProjectBasedLocation', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            this.location = results;
          }
        });
    },
    getTxnTypeList() {
      const moduleId = store.state.shared.moduleId;
      this.$store.dispatch('leases/getTxnTypeList', moduleId).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const typeList = results.map(type => {
            return {
              value: type.trx_type_dtl_id,
              text: type.trx_desc
            };
          });
          this.trxTypeList = typeList;
        }
      });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = item.value_code;
        this.operatingUnit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.fmsProject.text = item.value_code;
        this.fmsProject.value = item.value_set_dtl_id;
        this.fmsProject.code = item.lease_prj_code;
        this.getProjectBasedLocation();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_RECOVERY) {
        this.recoveryType.text = item.value_meaning;
        this.recoveryType.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.rateUom.text = item.value_meaning;
        this.rateUom.value = item.value_code;
      } else if (this.vsetCode === 'RECOVERY_BASIS') {
        this.recBasis.text = item.value_meaning;
        this.recBasis.value = item.value_code;
      }
    },
    openValueSetModal(vsetCode, areaType) {
      this.parent_value_set_id = this.intervalClass[0].detail_id;
      this.vsetCode = vsetCode;
      this.areaType = areaType;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.LOCATION ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_RECOVERY ||
        this.vsetCode === 'RECOVERY_BASIS'
      ) {
        this.parent_value_set_id = null;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getUomClass() {
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalClass = getValueAndText.filter(
              item => item.value === 'AREA'
            );
          }
        });
    },
    showHideTaxCategoryModal(flag) {
      this.showTaxCategory = flag;
    },
    selectedTaxCategory(item) {
      this.showTaxCategory = false;
      this.taxCategory.text = item.category_name;
      this.taxCategory.value = item.tax_cat_hdr_id;
    },
    showHideCodeCombinationModal(flag, name) {
      this.accountType = name;
      if (flag) {
        if (!this.legalEntity.value) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.fmsProject.code) {
          return alert(appStrings.DEPENDENTVSETMSG.PROJECTMSG);
        }
        this.combinationDetails.le_id = this.legalEntity.value;
        this.combinationDetails.project_code = this.fmsProject.code;
      }
      this.showCodeCombinationModal = flag;
    },
    selectedSegment(item) {
      if (this.accountType === 'expense') {
        this.expensesAccCcid = item.ccid;
        this.expensesAcc = item.segment_code;
        this.expensesAccMeaning = item.segment_meaning;
      } else if (this.accountType === 'liability') {
        this.liabilityAccCcid = item.ccid;
        this.liabilityAcc = item.segment_code;
        this.liabilityAccMeaning = item.segment_meaning;
      }
      this.showHideCodeCombinationModal(false);
    },
    disabledBeforeTodayAndTillEndOfMonth(date) {
      return commonHelper.disabledBeforeTodayAndTillEndOfMonth(
        date,
        this.fromDate
      );
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.legalEntity.text = null;
        this.legalEntity.value = null;
      } else if (vsetCode === 'OU_LIST') {
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (vsetCode === 'LEASE_PROJECT_LIST') {
        this.fmsProject.text = null;
        this.fmsProject.value = null;
      } else if (vsetCode === 'LEASE_RECOVERY') {
        this.recoveryType.text = null;
        this.recoveryType.value = null;
      } else if (vsetCode === 'UOM') {
        this.rateUom.text = null;
        this.rateUom.value = null;
      } else if (vsetCode === 'RECOVERY_BASIS') {
        this.recBasis.text = null;
        this.recBasis.value = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
