import DatePicker from 'vue2-datepicker';
import store from '../../../../../../../../store';
import tenantLease from '../../../../leases/tenantLease';
import appStrings from '@/app/utility/string.utility.js';
import leaseTower from '../../../../../../leaseAdmin/lease/masterLease/leaseTower';
import leaseFloor from '../../../../../../leaseAdmin/lease/masterLease/leaseFloor';
import leaseUnit from '../../../../../../leaseAdmin/lease/masterLease/leaseUnit';
import commonHelper from '@/app/utility/common.helper.utility';
import taxCategory from '../../../../../../taxation/taxCategory';
import codeCombination from '@/app/components/shared/codeCombination';
export default {
  name: 'selectInvoice',
  watch: {
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
    tempMsg: function() {
      if (this.tempMsg) {
        this.recoveryList = this.tempMsg.page;
        this.totalRows = this.tempMsg.total_elements;
      }
    },
    currentPage: function() {
      this.getTenantRecoveryInvoiceByIdDetails(
        this.investorRecoveryRowData.lease_tenant_recovery_batch_id
      );
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTenantRecoveryInvoiceByIdDetails(
        this.investorRecoveryRowData.lease_tenant_recovery_batch_id
      );
    }
  },
  components: {
    DatePicker,
    taxCategory,
    tenantLease,
    leaseTower,
    leaseFloor,
    leaseUnit,
    codeCombination
  },
  props: ['investorRecoveryRowData', 'editMode', 'tempMsg'],
  data() {
    return {
      componentName: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      showSubComponentModal: null,
      showCommonModal: null,
      towerName: {
        value: null,
        text: null
      },
      floorName: {
        value: null,
        text: null
      },
      unitName: {
        value: null,
        text: null
      },
      tenant: {
        value: null,
        text: null
      },
      selectedProject: {
        value: null,
        text: null
      },
      selectedTower: {
        value: null,
        text: null
      },
      selectedFloor: {
        value: null,
        text: null
      },
      fromDate: null,
      toDate: null,
      component: null,
      selectAllCheckBox: false,
      showAlert: false,
      isSuccess: false,
      loader: false,
      msg: false,
      responseMsg: [],
      segmentIndex: 0,
      taxIndex: 0,
      transactionTypeModal: false,
      showTaxCategory: false,
      vsetCode: null,
      // segmentFields: [
      //   {
      //     key: 'segment_meaning'
      //   },
      //   {
      //     key: 'segment_code'
      //   }
      // ],
      // segmentData: [],
      // showConcatSegmentModal: false,
      recoveryList: [
        {
          selectBox: false,
          lease_tenant_recovery_temp_id: 0,
          le_id: 0,
          le_name: null,

          lease_prj_id: 0,
          lease_prj_name: null,

          recovery_basis: null,
          recovery_basis_meaning: null,

          location_id: 0,
          location_name: null,

          trx_type_dtl_id: 0,
          trx_type: null,

          schedule_type_vset: null,
          schedule_type_vset_meaning: null,

          recovery_type_vset: null,
          recovery_type_vset_meaning: null,

          batch_date: null,
          batch_name: null,
          batch_description: null,
          recovery_rate: null,

          recovery_rate_uom_vset: null,
          recovery_rate_uom_vset_meaning: null,

          lease_recovery_amount: null,
          period_from: null,
          period_to: null,

          charge_ac_ccid: 0,
          charge_ac_ccid_dtl: null,
          charge_ac_ccid_meaning: null,

          debtor_ac_ccid: 0,
          debtor_ac_ccid_dtl: null,
          debtor_ac_ccid_meaning: null,

          tax_cat_id: 0,
          category_name: null,

          sac_code: null,
          hsn_code: null,

          batch_status_vset: null,
          batch_status_vset_meaning: null
        }
      ],

      recoveryFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'lease_prj_name',
          label: 'Project'
        },
        {
          key: 'lease_tower_name',
          label: 'Tower'
        },
        {
          key: 'lease_floor_name',
          label: 'Floor'
        },
        {
          key: 'lease_unit_name',
          label: 'Office'
        },
        {
          key: 'customer'
        },
        {
          key: 'customer_site'
        },
        {
          key: 'customer_site_address'
        },
        {
          key: 'lease_tenant_agreement_no',
          label: 'Lease Tenant No.'
        },
        {
          key: 'location_name',
          label: 'Location'
        },
        {
          key: 'recovery_basis_meaning',
          label: 'Recovery Basis'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type'
        },
        {
          key: 'schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'batch_date'
        },
        {
          key: 'batch_name'
        },
        {
          key: 'batch_description'
        },
        {
          key: 'period_from',
          label: 'From Date'
        },
        {
          key: 'period_to',
          label: 'To Date'
        },
        {
          key: 'area_uom_vset_meaning',
          label: 'Area UOM'
        },
        {
          key: 'area'
        },
        {
          key: 'lease_recovery_rate_uom_maening',
          label: 'Recovery Rate UOM'
        },
        {
          key: 'lease_recovery_rate',
          label: 'Recovery Rate'
        },
        {
          key: 'lease_recovery_amount',
          label: 'Amount'
        },
        {
          key: 'charge_ac_ccid_dtl',
          label: 'Charge Account'
        },
        {
          key: 'debtor_ac_ccid_dtl',
          label: 'Debtor Account'
        },
        {
          key: 'category_name',
          label: 'Tax Category'
        },
        {
          key: 'line_description'
        },
        {
          key: 'sac_code',
          label: 'SAC Code'
        },
        {
          key: 'hsn_code',
          label: 'HSN Code'
        }
      ],
      totalAmount: 0,
      showCodeCombinationModal: false,
      accountType: null,
      combinationDetails: {
        le_id: null,
        project_code: null
      }
    };
  },
  mounted() {
    this.getTxnTypeList();
    if (this.investorRecoveryRowData) {
      const selectTenantRecoveryById = this.investorRecoveryRowData
        .lease_tenant_recovery_batch_id;
      this.getTenantRecoveryInvoiceByIdDetails(selectTenantRecoveryById);
      this.selectedProject.text = this.investorRecoveryRowData.lease_prj_name;
      this.selectedProject.value = this.investorRecoveryRowData.lease_prj_id;
    }
  },
  methods: {
    fillDataFromRecordDetails() {
      this.recoveryList = this.recoveryList.map(elem => {
        elem.le_id = this.investorRecoveryRowData.le_id;
        elem.le_name = this.investorRecoveryRowData.le_name;

        elem.lease_prj_id = this.investorRecoveryRowData.lease_prj_id;
        elem.lease_prj_name = this.investorRecoveryRowData.lease_prj_name;

        elem.recovery_basis_meaning = this.investorRecoveryRowData.recovery_basis_meaning;
        elem.recovery_basis = this.investorRecoveryRowData.recovery_basis;

        elem.location_name = this.investorRecoveryRowData.location_name;
        elem.location_id = this.investorRecoveryRowData.location_id;

        elem.trx_type = this.investorRecoveryRowData.trx_type;
        elem.trx_type_dtl_id = this.investorRecoveryRowData.trx_type_dtl_id;

        elem.schedule_type_vset = this.investorRecoveryRowData.schedule_type_vset;
        elem.schedule_type_vset_meaning = this.investorRecoveryRowData.schedule_type_vset_meaning;

        elem.recovery_type_vset = this.investorRecoveryRowData.recovery_type_vset;
        elem.recovery_type_vset_meaning = this.investorRecoveryRowData.recovery_type_vset_meaning;

        elem.batch_date = this.investorRecoveryRowData.batch_date;
        elem.batch_name = this.investorRecoveryRowData.batch_name;
        elem.batch_description = this.investorRecoveryRowData.batch_description;
        elem.recovery_rate = this.investorRecoveryRowData.recovery_rate;

        elem.recovery_rate_uom_vset = this.investorRecoveryRowData.recovery_rate_uom_vset;
        elem.recovery_rate_uom_vset_meaning = this.investorRecoveryRowData.recovery_rate_uom_vset_meaning;

        elem.period_from = this.investorRecoveryRowData.period_from;
        elem.period_to = this.investorRecoveryRowData.period_to;

        elem.charge_ac_ccid = this.investorRecoveryRowData.charge_ac_ccid;
        elem.charge_ac_ccid_dtl = this.investorRecoveryRowData.charge_ac_ccid_dtl;
        elem.charge_ac_ccid_meaning = this.investorRecoveryRowData.charge_ac_ccid_meaning;

        elem.debtor_ac_ccid = this.investorRecoveryRowData.debtor_ac_ccid;
        elem.debtor_ac_ccid_dtl = this.investorRecoveryRowData.debtor_ac_ccid_dtl;
        elem.debtor_ac_ccid_meaning = this.investorRecoveryRowData.debtor_ac_ccid_meaning;

        elem.tax_cat_id = this.investorRecoveryRowData.tax_cat_id;
        elem.category_name = this.investorRecoveryRowData.category_name;

        elem.sac_code = this.investorRecoveryRowData.sac_code;
        elem.hsn_code = this.investorRecoveryRowData.hsn_code;

        elem.batch_status_vset = this.investorRecoveryRowData.batch_status_vset;
        elem.batch_status_vset_meaning = this.investorRecoveryRowData.batch_status_vset_meaning;
        return elem;
      });
    },
    getTenantRecoveryInvoiceByIdDetails(batchId) {
      this.loader = true;
      const payload = {
        searchParams: {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          lease_tower_id: this.towerName.value,
          lease_floor_id: this.floorName.value,
          lease_prj_unit_id: this.unitName.value,
          lease_tenant_agreement_hdr_id: this.tenant.value,
          period_from: this.fromDate,
          period_to: this.toDate
        },
        batchId: batchId
      };
      this.$store
        .dispatch('leaseRecovery/getTenantRecoveryInvoiceByIdDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.recoveryList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTxnTypeList() {
      const moduleId = store.state.shared.moduleId;
      this.$store.dispatch('leases/getTxnTypeList', moduleId).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const typeList = results.map(type => {
            return {
              value: type.trx_type_dtl_id,
              text: type.trx_desc
            };
          });
          this.transactionList = typeList;
        }
      });
    },
    searchParams() {
      this.getTenantRecoveryInvoiceByIdDetails(
        this.investorRecoveryRowData.lease_tenant_recovery_batch_id
      );
    },
    clearParams() {
      this.towerName.text = null;
      this.towerName.value = null;
      this.floorName.text = null;
      this.floorName.value = null;
      this.unitName.text = null;
      this.unitName.value = null;
      this.tenant.text = null;
      this.tenant.value = null;
      this.fromDate = null;
      this.toDate = null;
      this.getTenantRecoveryInvoiceByIdDetails(
        this.investorRecoveryRowData.lease_tenant_recovery_batch_id
      );
    },
    openCloseSubComponentModal(flag, componentName) {
      this.showSubComponentModal = flag;
      this.componentName = componentName;
    },
    selectedLeaseTenant(item) {
      this.tenant.text = item.lease_tenant_agreement_no;
      this.tenant.value = item.lease_tenant_agreement_hdr_id;
      this.showSubComponentModal = false;
    },
    getSelectedTower(item) {
      this.towerName.text = item.tower_name;
      this.towerName.value = item.lease_tower_id;
      this.showSubComponentModal = false;
    },
    getSelectedFloor(item) {
      this.floorName.text = item.floor_name;
      this.floorName.value = item.lease_floor_id;
      this.showSubComponentModal = false;
    },
    getSelectedUnit(item) {
      this.unitName.text = item.unit_display_name;
      this.unitName.value = item.lease_prj_unit_id;
      this.showSubComponentModal = false;
    },
    selectedTaxCategory(item) {
      this.recoveryList[this.taxIndex].category_name = item.category_name;
      this.recoveryList[this.taxIndex].tax_cat_hdr_id = item.tax_cat_hdr_id;
      this.showTaxCategory = false;
    },
    showHideTaxCategoryModal(flag, index) {
      this.showTaxCategory = flag;
      this.taxIndex = index;
    },
    showHideCodeCombinationModal(flag, name, index) {
      this.segmentIndex = index;
      this.accountType = name;
      if (flag) {
        if (!this.recoveryList[this.segmentIndex].le_id) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.recoveryList[this.segmentIndex].lease_prj_id) {
          return alert(appStrings.DEPENDENTVSETMSG.LEASEPRJMSG);
        }
        this.combinationDetails.le_id = this.recoveryList[
          this.segmentIndex
        ].le_id;
        this.combinationDetails.project_code = this.recoveryList[
          this.segmentIndex
        ].lease_prj_id;
      }
      this.showCodeCombinationModal = flag;
    },
    selectedSegment(item) {
      if (this.accountType === 'charge') {
        this.recoveryList[this.segmentIndex].charge_ac_ccid = item.ccid;
        this.recoveryList[this.segmentIndex].charge_ac_ccid_dtl =
          item.segment_code;
        this.recoveryList[this.segmentIndex].charge_ac_ccid_meaning =
          item.segment_meaning;
      } else if (this.accountType === 'debtor') {
        this.recoveryList[this.segmentIndex].debtor_ac_ccid = item.ccid;
        this.recoveryList[this.segmentIndex].debtor_ac_ccid_dtl =
          item.segment_code;
        this.recoveryList[this.segmentIndex].debtor_ac_ccid_meaning =
          item.segment_meaning;
      }
      this.showHideCodeCombinationModal(false);
    },
    selectBoxChecked(flag, index) {
      this.recoveryList[index].selectBox = flag;
      this.calculateAmount();
    },
    calculateAmountReduce() {
      const mapResult = this.recoveryList.map(
        item => item.lease_recovery_amount
      );
      return commonHelper.calculateTotal(mapResult);
    },
    calculateAmount() {
      let sum = 0;
      this.recoveryList.forEach(item => {
        if (item.selectBox) {
          sum += item.lease_recovery_amount;
        }
        this.totalAmount = parseFloat(sum).toFixed(2);
      });
    },
    checkUncheckAll() {
      this.recoveryList = this.recoveryList.map(data => {
        data.selectBox = this.selectAllCheckBox;
        return data;
      });
      this.calculateAmount();
    },
    getCalculatedLeaseRecoveryAmount(index) {
      const payload = {
        period_from: this.recoveryList[index].period_from,
        period_to: this.recoveryList[index].period_to,
        recovery_rate: this.recoveryList[index].lease_recovery_rate,
        area: this.recoveryList[index].area,
        recovery_basis: this.recoveryList[index].recovery_basis
      };
      this.loader = true;
      this.$store
        .dispatch('leaseRecovery/getCalculatedLeaseRecoveryAmount', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.recoveryList[index].lease_recovery_amount = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
