import DatePicker from 'vue2-datepicker';
import tenantLease from '../../../../leases/tenantLease';
import leaseTower from '../../../../../../leaseAdmin/lease/masterLease/leaseTower';
import leaseFloor from '../../../../../../leaseAdmin/lease/masterLease/leaseFloor';
import leaseUnit from '../../../../../../leaseAdmin/lease/masterLease/leaseUnit';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'unProcessed',
  watch: {
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
    tempMsg: function() {
      if (this.tempMsg) {
        this.recoveryList = this.tempMsg.page;
        this.totalRows = this.tempMsg.total_elements;
      }
    },
    currentPage: function() {
      this.getTenantRecoveryUnProcessByIdDetails(
        this.investorRecoveryRowData.lease_tenant_recovery_batch_id
      );
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTenantRecoveryUnProcessByIdDetails(
        this.investorRecoveryRowData.lease_tenant_recovery_batch_id
      );
    }
  },
  components: {
    DatePicker,
    tenantLease,
    leaseTower,
    leaseFloor,
    leaseUnit
  },
  props: ['investorRecoveryRowData', 'editMode', 'tempMsg'],
  data() {
    return {
      componentName: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      showSubComponentModal: null,
      showCommonModal: null,
      towerName: {
        value: null,
        text: null
      },
      floorName: {
        value: null,
        text: null
      },
      unitName: {
        value: null,
        text: null
      },
      tenant: {
        value: null,
        text: null
      },
      selectedProject: {
        value: null,
        text: null
      },
      flrName: null,
      fromDate: null,
      toDate: null,
      showAlert: false,
      isSuccess: false,
      loader: false,
      selectBox: false,
      batch_id: 0,
      responseMsg: '',
      postingStatus: null,
      selectAllCheckBox: false,
      debtor_ac_ccid_dtl_meaning: null,
      charge_ac_ccid_dtl_meaning: null,
      recoveryList: [],
      recoveryFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'lease_prj_name',
          label: 'Project'
        },
        {
          key: 'tower_name',
          label: 'Tower'
        },
        {
          key: 'floor_name',
          label: 'Floor'
        },
        {
          key: 'unit_name',
          label: 'Office'
        },
        {
          key: 'customer'
        },
        {
          key: 'customer_site'
        },
        {
          key: 'customer_site_address'
        },
        {
          key: 'lease_tenant_agreement_no',
          label: 'Lease Tenant No.'
        },
        {
          key: 'location_name',
          label: 'Location'
        },
        {
          key: 'recovery_basis_meaning',
          label: 'Recovery Basis'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type'
        },
        {
          key: 'schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'batch_date'
        },
        {
          key: 'batch_name'
        },
        {
          key: 'batch_description'
        },
        {
          key: 'period_from',
          label: 'From Date'
        },
        {
          key: 'period_to',
          label: 'To Date'
        },
        {
          key: 'area_uom_vset_meaning',
          label: 'Area UOM'
        },
        {
          key: 'area'
        },
        {
          key: 'lease_recovery_rate_uom_vset_meaning',
          label: 'Recovery Rate UOM'
        },
        {
          key: 'lease_recovery_rate',
          label: 'Recovery Rate'
        },
        {
          key: 'lease_recovery_amount',
          label: 'Amount'
        },
        {
          key: 'charge_ac_ccid_dtl',
          label: 'Charge Account'
        },
        {
          key: 'debtor_ac_ccid_dtl',
          label: 'Debtor Account'
        },
        {
          key: 'category_name',
          label: 'Tax Category'
        },
        {
          key: 'line_description'
        },
        {
          key: 'sac_code',
          label: 'SAC Code'
        },
        {
          key: 'hsn_code',
          label: 'HSN Code'
        },
        {
          key: 'posting_status_meaning',
          label: 'Posting Status'
        },
        {
          key: 'error_meaning',
          label: 'Error'
        }
      ],
      totalAmount: 0
    };
  },
  mounted() {
    if (this.investorRecoveryRowData) {
      const selectTenantRecoveryById = this.investorRecoveryRowData
        .lease_tenant_recovery_batch_id;
      this.getTenantRecoveryUnProcessByIdDetails(selectTenantRecoveryById);
      this.selectedProject.text = this.investorRecoveryRowData.lease_prj_name;
      this.selectedProject.value = this.investorRecoveryRowData.lease_prj_id;
    }
  },
  methods: {
    getTenantRecoveryUnProcessByIdDetails(batchId) {
      this.loader = true;
      const payload = {
        searchParams: {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          lease_tower_id: this.towerName.value,
          lease_floor_id: this.floorName.value,
          lease_prj_unit_id: this.unitName.value,
          lease_tenant_agreement_hdr_id: this.tenant.value,
          period_from: this.fromDate,
          period_to: this.toDate
        },
        batchId: batchId
      };
      this.$store
        .dispatch(
          'leaseRecovery/getTenantRecoveryUnProcessByIdDetails',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.recoveryList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            this.charge_ac_ccid_dtl_meaning =
              response.data.data.charge_ac_ccid_dtl_meaning;
            this.debtor_ac_ccid_dtl_meaning =
              response.data.data.debtor_ac_ccid_dtl_meaning;
            this.postingStatus = response.data.data.posting_status;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    searchParams() {
      this.getTenantRecoveryUnProcessByIdDetails(
        this.investorRecoveryRowData.lease_tenant_recovery_batch_id
      );
    },
    clearParams() {
      this.towerName.text = null;
      this.towerName.value = null;
      this.floorName.text = null;
      this.floorName.value = null;
      this.unitName.text = null;
      this.unitName.value = null;
      this.tenant.text = null;
      this.tenant.value = null;
      this.fromDate = null;
      this.toDate = null;
      this.getTenantRecoveryUnProcessByIdDetails(
        this.investorRecoveryRowData.lease_tenant_recovery_batch_id
      );
    },
    openCloseSubComponentModal(flag, componentName) {
      this.showSubComponentModal = flag;
      this.componentName = componentName;
    },
    selectedLeaseTenant(item) {
      this.tenant.text = item.lease_tenant_agreement_no;
      this.tenant.value = item.lease_tenant_agreement_hdr_id;
      this.showSubComponentModal = false;
    },
    getSelectedTower(item) {
      this.towerName.text = item.tower_name;
      this.towerName.value = item.lease_tower_id;
      this.showSubComponentModal = false;
    },
    getSelectedFloor(item) {
      this.floorName.text = item.floor_name;
      this.floorName.value = item.lease_floor_id;
      this.showSubComponentModal = false;
    },
    getSelectedUnit(item) {
      this.unitName.text = item.unit_display_name;
      this.unitName.value = item.lease_prj_unit_id;
      this.showSubComponentModal = false;
    },
    selectBoxChecked(flag, index) {
      this.recoveryList[index].selectBox = flag;
      this.calculateAmount();
    },
    calculateAmount() {
      let sum = 0;
      this.recoveryList.forEach(item => {
        if (item.selectBox) {
          sum += item.lease_recovery_amount;
        }
        this.totalAmount = parseFloat(sum).toFixed(2);
      });
    },
    checkUncheckAll() {
      this.recoveryList = this.recoveryList.map(data => {
        if (!/[eu]/i.test(data.posting_status)) {
          data.selectBox = false;
        } else {
          data.selectBox = this.selectAllCheckBox;
        }
        return data;
      });
      this.calculateAmount();
    }
  }
};
