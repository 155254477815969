import selectInvoice from './selectInvoice';
import processed from './processed';
import unProcessed from './unProcessed';
import appStrings from '@/app/utility/string.utility';
import taxCategory from '../../../../../taxation/taxCategory';
import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import codeCombination from '@/app/components/shared/codeCombination';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'recoveryDetails',
  components: {
    selectInvoice,
    processed,
    unProcessed,
    ModelSelect,
    DatePicker,
    taxCategory,
    codeCombination
  },
  props: ['tenantRecoveryRowDetail'],
  data() {
    return {
      showAlert: false,
      isSuccess: false,
      editMode: false,
      loader: false,
      responseMsg: [],
      showValueSetModal: false,
      showTaxCategory: false,
      showConcatSegmentModal: false,
      parent_value_set_id: null,
      scheduleTypeName: 'Recovery',
      scheduleTypeCode: 'SRECOVERY',
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      fmsProject: {
        value: null,
        text: null,
        code: null
      },
      location: {
        location_id: null,
        location_name: null
      },
      trxType: {
        value: null,
        text: null
      },
      scheduleType: {
        value: null,
        text: null
      },
      recoveryType: {
        value: null,
        text: null
      },
      batchDate: null,
      batchName: null,
      description: null,
      lineDescription: null,
      recBasis: {
        value: null,
        text: null
      },
      rateUom: {
        value: null,
        text: null
      },
      tempMsg: [],
      ratePerSqft: null,
      fromDate: null,
      toDate: null,
      expensesAcc: null,
      expensesCcid: null,
      expensesAccMeaning: null,
      liabilityAcc: null,
      liabilityCcid: null,
      liabilityAccMeaning: null,
      taxCategory: {
        value: null,
        text: null
      },
      sacCode: null,
      hsnCode: null,
      status: null,
      batchId: null,
      isViewMore: false,
      activeTab: 'invoice',
      prjType: 'LEASE',
      depVsetParam: null,
      showCodeCombinationModal: false,
      accountType: null,
      combinationDetails: {
        le_id: null,
        project_code: null
      }
    };
  },
  validations: {
    legalEntity: {
      text: { required }
    },
    operatingUnit: {
      text: { required }
    },
    fmsProject: {
      text: { required }
    },
    location: {
      location_name: { required }
    },
    trxType: {
      text: {
        required
      }
    },
    recoveryType: {
      text: { required }
    },
    batchDate: { required },
    recBasis: {
      text: { required }
    },
    rateUom: {
      text: { required }
    },
    fromDate: { required },
    toDate: { required },
    expensesAcc: { required },
    liabilityAcc: { required }
  },
  mounted() {
    if (this.tenantRecoveryRowDetail) {
      this.batchId = this.tenantRecoveryRowDetail.lease_tenant_recovery_batch_id;
      this.getInvestorRecoveryDetails(this.batchId);
      this.getUomClass();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.responseMsg = [];
          this.addEditLeaseTenantRecovery();
          if (this.activeTab === 'invoice') {
            this.processTenantRecoveryInvoices();
          } else if (this.activeTab === 'unprocess') {
            this.approveTenantRecoveryInvoices();
          }
        }
      }
    });
  },
  methods: {
    addEditLeaseTenantRecovery() {
      // if (this.sacCode || this.hsnCode) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const payload = {
            lease_tenant_recovery_batch_details: {
              lease_tenant_recovery_batch_id: this.batchId,
              le_id: Number(this.legalEntity.value),
              ou_id: Number(this.operatingUnit.value),
              lease_prj_id: Number(this.fmsProject.value),
              location_id: Number(this.location.location_id),
              trx_type_dtl_id: Number(this.trxType.value),
              schedule_type_vset: this.scheduleType.value,
              recovery_type_vset: this.recoveryType.value,
              batch_date: this.batchDate,
              batch_description: this.description,
              recovery_basis: this.recBasis.value,
              recovery_rate_uom_vset: this.rateUom.value,
              recovery_rate: Number(this.ratePerSqft),
              period_from: this.fromDate,
              period_to: this.toDate,
              charge_ac_ccid: Number(this.expensesCcid),
              debtor_ac_ccid: Number(this.liabilityCcid),
              tax_cat_id: Number(this.taxCategory.value),
              sac_code: Number(this.sacCode),
              hsn_code: Number(this.hsnCode),
              batch_status_vset: this.status,
              line_description: this.lineDescription
            }
          };
          this.loader = true;
          this.$store
            .dispatch('leaseRecovery/addEditLeaseTenantRecovery', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                // this.responseMsg = response.data.message;
                this.responseMsg.push(
                  `Header Details: ${response.data.message}`
                );
                this.batchId =
                  response.data.data.lease_tenant_recovery_batch_id;
                this.batchName = response.data.data.batch_name;
                this.status = response.data.data.batch_status_vset;
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Header Details: ${response.response.data.message}`
                );
                // this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Header Details: ${appStrings.autoFailedMsg}`
              );
              // this.responseMsg = appStrings.autoFailedMsg;
            });
        // }
      } 
      // else {
      //   alert('Please Enter SAC or HSN Code');
      // }
    },
    getInvestorRecoveryDetails(leaseTenantRecoveryBatchId) {
      this.loader = true;
      this.$store
        .dispatch(
          'leaseRecovery/getTenantRecoveryDetails',
          leaseTenantRecoveryBatchId
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.legalEntity.text = results.le_name;
            this.legalEntity.value = results.le_id;
            this.operatingUnit.text = results.ou_name;
            this.operatingUnit.value = results.ou_id;
            this.fmsProject.text = results.lease_prj_name;
            this.fmsProject.value = results.lease_prj_id;
            this.fmsProject.code = results.lease_prj_code;
            this.location.location_name = results.location_name;
            this.location.location_id = results.location_id;
            this.trxType.text = results.trx_type;
            this.trxType.value = results.trx_type_dtl_id;
            this.scheduleType.text = results.schedule_type_vset_meaning;
            this.scheduleType.value = results.schedule_type_vset;
            this.recoveryType.text = results.recovery_type_vset_meaning;
            this.recoveryType.value = results.recovery_type_vset;
            this.batchDate = results.batch_date;
            this.batchName = results.batch_name;
            this.description = results.batch_description;
            this.recBasis.text = results.recovery_basis_meaning;
            this.recBasis.value = results.recovery_basis;
            this.rateUom.text = results.recovery_rate_uom_vset_meaning;
            this.rateUom.value = results.recovery_rate_uom_vset;
            this.ratePerSqft = results.recovery_rate;
            this.fromDate = results.period_from;
            this.toDate = results.period_to;
            this.expensesAcc = results.charge_ac_ccid_dtl;
            this.expensesCcid = results.charge_ac_ccid;
            this.expensesAccMeaning = results.charge_ac_ccid_meaning;
            this.liabilityAcc = results.debtor_ac_ccid_dtl;
            this.liabilityCcid = results.debtor_ac_ccid;
            this.liabilityAccMeaning = results.debtor_ac_ccid_meaning;
            this.taxCategory.text = results.category_name;
            this.taxCategory.value = results.tax_cat_id;
            this.sacCode = results.sac_code;
            this.hsnCode = results.hsn_code;
            this.status = results.batch_status_vset_meaning;
            this.lineDescription = results.line_description;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    processTenantRecoveryInvoices() {
      const objRefrence = this.$refs.invoice.recoveryList;
      const filterData = objRefrence.filter(data => data.selectBox);
      const recoveryDetails = filterData.map(elem => {
        return {
          lease_tenant_recovery_temp_id: elem.lease_tenant_recovery_temp_id
            ? elem.lease_tenant_recovery_temp_id
            : 0,
          lease_tenant_agreement_hdr_id: elem.lease_tenant_agreement_hdr_id,
          customer_id: elem.customer_id,
          customer_site_id: elem.customer_site_id,
          lease_prj_unit_id: elem.lease_prj_unit_id,
          recovery_basis: elem.recovery_basis,
          schedule_type_vset: elem.schedule_type_vset,
          recovery_type_vset: elem.recovery_type_vset,
          trx_type_dtl_id: elem.trx_type_dtl_id,
          lease_recovery_period: elem.lease_recovery_period
            ? elem.lease_recovery_period
            : 0,
          lease_recovery_period_uom_vset: null,
          lease_recovery_rate: Number(elem.lease_recovery_rate),
          lease_recovery_rate_uom_vset: elem.lease_recovery_rate_uom_vset,
          lease_recovery_amount: Number(elem.lease_recovery_amount),
          period_from: elem.period_from,
          period_to: elem.period_to,
          charge_ac_ccid: elem.charge_ac_ccid,
          debtor_ac_ccid: elem.debtor_ac_ccid,
          tax_cat_id: elem.tax_cat_id,
          location_id: elem.location_id,
          sac_code: elem.sac_code,
          hsn_code: elem.hsn_code ? elem.hsn_code : null,
          posting_status: elem.posting_status ? elem.posting_status : 'U',
          line_description: elem.line_description
        };
      });
      const payload = {
        lease_tenant_recovery_batch_id: this.tenantRecoveryRowDetail
          .lease_tenant_recovery_batch_id,
        lease_tenant_recovery_details: recoveryDetails ? recoveryDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('leaseRecovery/invoiceTenantRecoveryDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            // this.responseMsg = response.data.message;
            this.responseMsg.push(`Process Details: ${response.data.message}`);
            this.tempMsg = response.data.data;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg.push(
              `Process Details: ${response.response.data.message}`
            );
            // this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg.push(`Process Details: ${appStrings.autoFailedMsg}`);
          // this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    approveTenantRecoveryInvoices() {
      const objRefrence = this.$refs.unprocess.recoveryList;
      const filterData = objRefrence.filter(data => data.selectBox);
      const recoveryDetails = filterData.map(elem => {
        return {
          lease_tenant_agreement_recovery_id: elem.lease_tenant_agreement_recovery_id
            ? elem.lease_tenant_agreement_recovery_id
            : 0,
          lease_tenant_agreement_hdr_id: elem.lease_tenant_agreement_hdr_id,
          customer_id: elem.customer_id,
          customer_site_id: elem.customer_site_id,
          recovery_basis: elem.recovery_basis,
          schedule_type_vset: elem.schedule_type_vset,
          recovery_type_vset: elem.recovery_type_vset,
          trx_type_dtl_id: elem.trx_type_dtl_id,
          lease_recovery_period: elem.lease_recovery_period,
          lease_recovery_period_uom_vset: elem.lease_recovery_period_uom_vset,
          lease_recovery_rate: Number(elem.lease_recovery_rate),
          lease_recovery_rate_uom_vset: elem.lease_recovery_rate_uom_vset,
          lease_recovery_amount: Number(elem.lease_recovery_amount),
          period_from: elem.period_from,
          period_to: elem.period_to,
          charge_ac_ccid: elem.charge_ac_ccid,
          debtor_ac_ccid: elem.debtor_ac_ccid,
          tax_cat_id: elem.tax_cat_id,
          location_id: elem.location_id,
          sac_code: elem.sac_code,
          hsn_code: elem.hsn_code,
          recovery_process_status: elem.posting_status
            ? elem.posting_status
            : 'U',
          line_description: elem.line_description
        };
      });
      const payload = {
        lease_tenant_recovery_batch_id: this.tenantRecoveryRowDetail
          .lease_tenant_recovery_batch_id,
        lease_tenant_recovery_batch_approve_details: recoveryDetails
          ? recoveryDetails
          : null
      };
      this.loader = true;
      this.$store
        .dispatch('leaseRecovery/processTenantRecoveryDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            // this.responseMsg = response.data.message;
            this.responseMsg.push(
              `Unprocess Details: ${response.data.message}`
            );
            this.recoveryList = response.data.data;
            this.tempMsg = response.data.data;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg.push(
              `Unprocess Details: ${response.response.data.message}`
            );
            // this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg.push(
            `Unprocess Details: ${appStrings.autoFailedMsg}`
          );
          // this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedTab(tab) {
      this.activeTab = tab;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
        this.fmsProject.text = null;
        this.fmsProject.value = null;
        this.fmsProject.code = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = item.value_code;
        this.operatingUnit.value = item.value_set_dtl_id;
        this.fmsProject.text = null;
        this.fmsProject.value = null;
        this.fmsProject.code = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.fmsProject.text = item.value_code;
        this.fmsProject.value = item.value_set_dtl_id;
        this.fmsProject.code = item.lease_prj_code;
        this.getProjectBasedLocation();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_RECOVERY) {
        this.recoveryType.text = item.value_meaning;
        this.recoveryType.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.rateUom.text = item.value_meaning;
        this.rateUom.value = item.value_code;
      } else if (this.vsetCode === 'RECOVERY_BASIS') {
        this.recBasis.text = item.value_meaning;
        this.recBasis.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TRX_TYPE) {
        this.trxType.text = item.trx_code;
        this.trxType.value = item.trx_type_dtl_id;
      }
    },
    openValueSetModal(vsetCode, areaType) {
      // this.parent_value_set_id = this.intervalClass[0].detail_id;
      this.vsetCode = vsetCode;
      this.areaType = areaType;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TRX_TYPE) {
        this.parent_value_set_id = this.$store.state.shared.moduleId;
      } else if (this.vsetCode === 'UOM') {
        this.parent_value_set_id = this.intervalClass[0].detail_id;
      }
      // else if (
      //   this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
      //   this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST ||
      //   this.vsetCode === appStrings.VALUESETTYPE.LOCATION ||
      //   this.vsetCode === appStrings.VALUESETTYPE.LEASE_RECOVERY ||
      //   this.vsetCode === 'RECOVERY_BASIS'
      // ) {
      //   this.parent_value_set_id = null;
      // }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getUomClass() {
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalClass = getValueAndText.filter(
              item => item.value === 'AREA'
            );
          }
        });
    },
    showHideTaxCategoryModal(flag) {
      this.showTaxCategory = flag;
    },
    selectedTaxCategory(item) {
      this.showTaxCategory = false;
      this.taxCategory.text = item.category_name;
      this.taxCategory.value = item.tax_cat_hdr_id;
    },
    showHideCodeCombinationModal(flag, name) {
      this.accountType = name;
      if (flag) {
        if (!this.legalEntity.value) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.fmsProject.code) {
          return alert(appStrings.DEPENDENTVSETMSG.PROJECTMSG);
        }
        this.combinationDetails.le_id = this.legalEntity.value;
        this.combinationDetails.project_code = this.fmsProject.code;
      }
      this.showCodeCombinationModal = flag;
    },
    selectedSegment(item) {
      if (this.accountType === 'expense') {
        this.expensesCcid = item.ccid;
        this.expensesAcc = item.segment_code;
        this.expensesAccMeaning = item.segment_meaning;
      } else if (this.accountType === 'liability') {
        this.liabilityCcid = item.ccid;
        this.liabilityAcc = item.segment_code;
        this.liabilityAccMeaning = item.segment_meaning;
      }
      this.showHideCodeCombinationModal(false);
    },
    getProjectBasedLocation() {
      const payload = {
        prjType: this.prjType,
        prjId: this.fmsProject.value
      };
      this.$store
        .dispatch('leaseRecovery/getProjectBasedLocation', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            this.location = results;
          }
        });
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'LEASE_RECOVERY') {
        this.recoveryType.text = null;
        this.recoveryType.value = null;
      } else if (vsetCode === 'TRX_TYPE') {
        this.trxType.text = null;
        this.trxType.value = null;
      } else if (vsetCode === 'UOM') {
        this.rateUom.text = null;
        this.rateUom.value = null;
      } else if (vsetCode === 'RECOVERY_BASIS') {
        this.recBasis.text = null;
        this.recBasis.value = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
